export const estateAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:read',
  hmac_version: '2',
  hmac: '',
  identifier: '',
  parameters: {
    data: [
      'Id',
      'plz',
      'ort',
      'strasse',
      'hausnummer',
      'land',
      'bundesland',
      'objekttitel',
      'objekttyp',
      'objektbeschreibung',
      'objektnr_extern',
      'ausstatt_beschr',
      'sonstige_angaben',
      'nutzungsart',
      'lage',
      'baujahr',
      'energieausweistyp',
      'energieausweis_gueltig_bis',
      'energyClass',
      'endenergiebedarf',
      'energietraeger',
      'waehrung',
      'kaufpreis',
      'kaltmiete',
      'freitext_preis',
      'letzte_aktion',
      'hausgeld',
      'wohnflaeche',
      'nutzflaeche',
      'etagen_zahl',
      'grundstuecksflaeche',
      'anzahl_zimmer',
      'anzahl_badezimmer',
      'einliegerwohnung',
      'anzahl_schlafzimmer',
      'objektart',
      'vermarktungsart',
      'status',
      'verkauft',
      'heizungsart',
      'fahrstuhl',
      'kueche',
      'unterkellert',
      'balkon',
      'gartennutzung',
      'barrierefrei'
    ],
    filter: {
      status: [{ op: '=', val: '1' }],
      objektart: [{ op: '=', val: ['Haus'] }],
      nutzungsart: [{ op: '=', val: ['Wohnen'] }]
    },
    filterid: null,
    formatoutput: true,
    listlimit: 500,
    listoffset: 0,
    sortby: { letzte_aktion: 'DSC' }
  },
  resourceid: null,
  resourcetype: 'estate',
  timestamp: null
};

export const estateImageAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:get',
  hmac_version: '2',
  hmac: '',
  identifier: '',
  parameters: {
    data: [],
    estateids: [],
    categories: ['Titelbild'],
    size: '800x600',
    language: 'DEU',
    listlimit: 500,
    listoffset: 0
  },
  resourceid: null,
  resourcetype: 'estatepictures',
  timestamp: null
};

export const estateImageThumbnailAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:get',
  hmac_version: '2',
  hmac: '',
  identifier: '',
  parameters: {
    data: [],
    estateids: [],
    categories: ['Foto'],
    size: '400x300',
    language: 'DEU',
    listlimit: 500,
    listoffset: 0
  },
  resourceid: null,
  resourcetype: 'estatepictures',
  timestamp: null
};
